import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { ReportService } from './../../services/report-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

@autoinject
export class ServiceSummary {
  private reportContent: string;
  private serviceId: number;
  private generateServiceSummaryReportProgress: boolean = false;  

  constructor(private controller: DialogController, 
    private reportService: ReportService, 
    private toastService: ToastService,
    private errorService: ErrorService) {
  }

  public activate(model) {    
    this.reportContent = model.reportContent;   
    this.serviceId = model.serviceId;
  }

  private generateServiceSummaryReport() {
    if (this.generateServiceSummaryReportProgress) { return; }

    this.generateServiceSummaryReportProgress = true;

    this.reportService.getServiceSummaryReport(this.serviceId)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.generateServiceSummaryReportProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.generateServiceSummaryReportProgress = false;
      });    
  }
}
