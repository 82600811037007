import { DeleteDialogService } from './../../services/delete-dialog-service';
import { ErrorService } from './../../services/error-service';
import { ToastService } from './../../services/toast-service';
import { UserService } from './../../services/user-service';
import { ServiceTimeItemService } from './../../services/service-time-item-service';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ServiceTaskTypeService } from 'services/service-task-type-service';
import { ServiceService } from 'services/service-service';
import { UserModels } from 'models/UserModels';
import { InvoiceBadge } from 'components/badge/invoice-badge';
import { ValidationController, ValidationControllerFactory, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';

@autoinject
export class ServiceTimeItems {
  @bindable() private serviceId;
  @bindable() private serviceTaskTypeId?;
  @bindable() private invoiceHoursStatus: string;

  private serviceTimeItems: Array<Models.ServiceTimeItem>;
  private timeItem: Models.ServiceTimeItem = new Models.ServiceTimeItem();
  @observable({ changeHandler: 'onTimeItemEditVisibleChanged' })
  protected timeItemEditFormVisible: boolean = false;
  protected timeItemNewFormVisible: boolean = false;
  protected timeItemsSum: number = null;
  protected timeItemsExpanded: boolean = false;
  protected newTimeItemFocused: boolean = false;
  protected currentUserName: string = null;
  protected sumTimeItemsLabelKey: string = null;
  protected serviceTaskTypes: Models.ServiceTaskType[];
  private serviceUsers: (Models.ServiceUser & { DisplayName: string })[];
  private selectedUser: string;

  private currentUser: UserModels.User;

  private validationController: ValidationController;

  constructor(
    private serviceTimeItemService: ServiceTimeItemService,
    private userService: UserService,
    private deleteDialogService: DeleteDialogService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private serviceService: ServiceService,
    private serviceTaskTypeService: ServiceTaskTypeService,
    private t: I18N,
    private validator: Validator,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  async bind() {
    if (this.serviceId) {
      this.sumTimeItemsLabelKey = this.serviceTaskTypeId
        ? 'service.sumTimeItems_' + this.serviceTaskTypeId
        : 'service.sumTimeItems_total';

      const user = await this.userService.getCurrentUser();
      this.currentUserName = user.Name;
      this.currentUser = user;

      // Preselect the current user
      this.selectedUser = user.Id;

      this.getServiceTimeItems();

      if (!this.serviceTaskTypeId) {
        this.timeItemsExpanded = true;
        void this.getServiceTaskTypes();
      }
    }
  }

  protected applyValidationRules(item: Models.ServiceTimeItem) {
    ValidationRules.ensure('Hours').required().withMessage(this.t.tr('general.requiredField')).on(item);
  }

  private async doValidate(item: Models.ServiceTimeItem) {
    this.applyValidationRules(item);
    const validation = await this.validationController.validate({
      object: item,
    });
    return validation.valid;
  }

  onTimeItemEditVisibleChanged(newValue: number) {
    if (newValue) {
      this.selectedUser = this.serviceTimeItems.find((si) => si.Id == newValue)?.UserId ?? '';
    }
  }

  private async getServiceUsers() {
    try {
      this.serviceUsers = (await this.serviceService.getServiceUsers(this.serviceId)).map((s) => {
        return { ...s, DisplayName: `[${s.StationName}] ${s.UserName}` };
      });
      return this.serviceUsers;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private getServiceTimeItems() {
    this.serviceTimeItemService
      .getAll(
        `?$filter=ServiceId eq ${this.serviceId} ${
          this.serviceTaskTypeId ? 'and ServiceTaskTypeId eq ' + this.serviceTaskTypeId : ''
        }&$orderby=Date`
      )
      .then((res) => {
        this.serviceTimeItems = res;
        this.timeItemsSum = res.reduce((a, { Hours }) => a + Hours || 0, 0);
        const invoiceHoursStatusList = this.serviceTimeItems.map((x) => (x.ServiceInvoiceId ? true : false));
        this.invoiceHoursStatus = InvoiceBadge.GetInvoiceType(invoiceHoursStatusList);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async getServiceTaskTypes() {
    this.serviceTaskTypes = await this.serviceTaskTypeService.getAll();
  }

  private addTimeItem() {
    this.timeItemsExpanded = true;
    this.timeItemNewFormVisible = true;
    this.timeItemEditFormVisible = null;
    this.timeItem = new Models.ServiceTimeItem();
    this.timeItem.ServiceId = this.serviceId;
    this.timeItem.ServiceTaskTypeId = this.serviceTaskTypeId;

    setTimeout(() => {
      this.newTimeItemFocused = true;
    });
  }

  private async getUserForServiceItem() {
    const { Id, Name } = await this.userService.getCurrentUser();
    const selectedUser = this.serviceUsers.find((su) => (su.UserId = this.selectedUser));

    if (!selectedUser) {
      return { Id, Name, CurrentUserId: Id };
    } else {
      return {
        Id: selectedUser.UserId,
        Name: selectedUser.UserName,
        CurrentUserId: Id,
      };
    }
  }

  private async createTimeItem() {
    const { Id, Name, CurrentUserId } = await this.getUserForServiceItem();

    this.timeItem.UserId = Id;
    this.timeItem.UserName = Name;
    this.timeItem.RegisteredByUserId = CurrentUserId;

    if (!(await this.doValidate(this.timeItem))) {
      return;
    }

    this.serviceTimeItemService.post(this.timeItem).then((res) => {
      this.toastService.showSuccess('service.timeItemCreated');
      this.getServiceTimeItems();
      this.timeItemNewFormVisible = false;
      this.selectedUser = this.currentUser.Id;

      if (this.serviceTaskTypeId) {
        this.timeItemsExpanded = false;
      }
    });
  }

  private async updateTimeItem(item: Models.ServiceTimeItem) {
    const { Id, Name, CurrentUserId } = await this.getUserForServiceItem();

    if (item.UserId !== this.selectedUser) {
      item.UserId = Id;
      item.UserName = Name;
    }

    if (!(await this.doValidate(item))) {
      return;
    }
    // If the item does not have a registered by, set to the current user.
    if (!item.RegisteredByUserId) item.RegisteredByUserId = CurrentUserId;

    delete item.ServiceTaskType;

    this.serviceTimeItemService
      .put(item, item.Id)
      .then((res) => {
        this.toastService.showSuccess('service.timeItemUpdated');
        this.getServiceTimeItems();
        this.timeItemEditFormVisible = null;
        this.selectedUser = this.currentUser.Id;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteTimeItem(itemId: number) {
    this.deleteDialogService.confirmBeforeDelete(() => {
      this.serviceTimeItemService
        .delete(itemId)
        .then((res) => {
          this.getServiceTimeItems();
          this.toastService.showSuccess('service.timeItemDeleted');
        })
        .catch((err) => this.errorService.handleError(err));
    });
  }

  private cancelEditTimeItem() {
    this.getServiceTimeItems();
    this.timeItemEditFormVisible = null;
    this.selectedUser = this.currentUser.Id;
  }
}
