import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { NettingService } from 'services/netting-service';
import { ErrorService } from 'services/error-service';
import { AttachmentHandlerFactory, CustomerDocumentsHandler } from 'services/attachments';
import { CustomerDocumentType } from 'models';
@autoinject
export class ServiceSidebarBasic {
  @bindable protected net: Models.Net;
  netChanged(net: Models.Net) {
    this.documentHandler = this.attachmentHandlerFactory.customerAttachmentHandler({
      customerId: net.CustomerId,
      documentType: CustomerDocumentType.ServiceInformation,
    });
  }
  @bindable protected netDimension: Models.NetDimension;
  @bindable protected specialProductDimension: Models.SpecialProductDimension;
  @bindable protected service: Models.Service;

  protected documentHandler: CustomerDocumentsHandler;

  constructor(
    private errorService: ErrorService,
    private nettingService: NettingService,
    private attachmentHandlerFactory: AttachmentHandlerFactory
  ) { }
}
