import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, BindingEngine, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ServiceService } from 'services/service-service';
import { ServiceStationService } from 'services/service-station-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { ServiceStationPipelineService } from './../../services/service-station-pipeline-service';
import { UserService } from './../../services/user-service';
import { ServiceDialog } from '../service-dialog/service-dialog';
import * as moment from 'moment';

declare const Draggable;

@autoinject
export class ServiceQueue {
  private saving: boolean = false;
  private serviceStationId: number;
  private serviceStation: Models.ServiceStation;
  private pipelines: any;
  private queuedServices: any;
  private preQueueTotalItems: number = 0;
  private pipelineData: any;
  private pipelineSearchInputHasFocus: boolean = false;
  private pipelineFilters: {
    searchText: string;
  } = {
    searchText: '',
  };
  private language: string;

  private searchInputHasFocus: boolean = false;

  private pageSize: number = 30;
  @bindable currentPage: number = 1;

  private serviceStations: Array<Models.ServiceStation>;
  private openServiceAsDialog: boolean = true;

  private filters = {
    searchText: '',
    customerId: null,
    plannedDeliveryDateFrom: null,
    plannedDeliveryDateTo: null,
    hasPlannedDeliveryDate: null,
    statuses: {
      Washed: false,
      NotWashed: false,
      Repaired: false,
      NotRepaired: false,
      Tested: false,
      NotTested: false,
      Antifouled: false,
      NotAntifouled: false,
      PutOnStorage: false,
      PackedAndReady: false,
    },
  };

  private sortable: any;

  private preQueueServices: Array<any>;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private router: Router,
    private serviceService: ServiceService,
    private serviceStationService: ServiceStationService,
    private pipelineService: ServiceStationPipelineService,
    private toastService: ToastService,
    private bindingEngine: BindingEngine,
    private userService: UserService,
    private dialogService: DialogService
  ) {
    this.language = this.i18n.getLocale();
  }

  private attached() {
    this.bindingEngine.propertyObserver(this.pipelineFilters, 'searchText').subscribe((newValue, oldValue) => {
      this.filterPipelineServices(newValue, oldValue);
    });

    this.bindingEngine.propertyObserver(this.filters, 'searchText').subscribe((newValue, oldValue) => {
      this.currentPage = 1;
      this.refreshPreQueue();
    });

    this.eventAggregator.subscribe('lang-changed', () => {
      this.language = this.i18n.getLocale();
      this.getServiceStationData();
    });

    Promise.all([this.userService.getCurrentUser(), this.serviceStationService.getAllCached()]).then((responses) => {
      let user = responses[0];
      this.serviceStationId = user.ServiceStationId;

      this.serviceStations = responses[1].filter((x) => !x.IsDeleted).sort((a, b) => (a.Name > b.Name ? 1 : -1));

      if (!this.serviceStationId) {
        this.changeServiceStation(this.serviceStations[0]);
      } else {
        let serviceStation = this.serviceStations.find((x) => x.Id === this.serviceStationId);
        this.changeServiceStation(serviceStation);
      }
    });
  }

  private detached() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  }

  getServiceStationData() {
    Promise.all([
      this.pipelineService.getAll(
        '?$filter=ServiceStationId eq ' +
          this.serviceStationId +
          '&$orderby=ServiceStationPipelineType/SortIndex,SortIndex'
      ),
      this.serviceService.getQueuedServices(this.serviceStationId),
    ])
      .then((res: any) => {
        const pipelineData = [];

        // loop through the servicestation's available pipelines
        res[0].forEach((pipeline) => {
          pipeline.Services = res[1].filter((x) => x.ServiceStationPipelineId === pipeline.Id && !x.IsInPipelineQueue);
          pipeline.ServicesQueued = res[1].filter(
            (x) => x.ServiceStationPipelineId === pipeline.Id && x.IsInPipelineQueue
          );

          let pipelineType = pipelineData.find(
            (x) => x.ServiceStationPipelineTypeId === pipeline.ServiceStationPipelineTypeId
          );

          if (!pipelineType) {
            pipelineType = {
              PipelineType:
                this.language === 'nb-NO'
                  ? pipeline.ServiceStationPipelineType.Name
                  : pipeline.ServiceStationPipelineType.NameEn,
              ServiceStationPipelineTypeId: pipeline.ServiceStationPipelineTypeId,
              Pipelines: [],
            };

            pipelineData.push(pipelineType);
          }

          pipelineType.Pipelines.push(pipeline);
        });

        this.pipelineData = pipelineData;

        this.queuedServices = res[1];

        // https://codepen.io/brettdonald/pen/zMVMjd

        // --------------------------------------------------------------------------
        // NOTICE! PLEASE READ
        /*

        Had to put this code inside a timeout because the DOM
        needs some milliseconds to create the dynamic setup.

        If you try to run this code outside an timeout the document.querySelectorAll
        will return empty.

        Will have to find a better and more stable solution than setTimeout.

        */
        // --------------------------------------------------------------------------

        if (!this.sortable) {
          setTimeout(() => {
            this.sortable = new Draggable.Sortable(document.querySelectorAll('[sortable]'), {
              draggable: '[sortitem]',
              distance: 50,
            });

            this.sortable.on('sortable:stop', (event) => {
              const serviceId = event.dragEvent.source.children[0].attributes['data-id'].value;
              const isInQueue = event.newContainer.hasAttribute('queue');
              const newSortIndex = event.newIndex + 1;
              console.log(event);
              let newPipelineId = event.newContainer.id;
              if (newPipelineId === '') {
                newPipelineId = null;
              } else {
                newPipelineId = parseInt(newPipelineId);
              }

              // find the service we have moved
              let service = this.queuedServices.find((x) => x.ServiceId == serviceId);
              const servicesInqueue = this.queuedServices
                .filter((x) => {
                  return x.ServiceStationPipelineId == newPipelineId && x.IsInPipelineQueue == isInQueue;
                })
                ?.sort((a, b) => a.SortIndex - b.SortIndex);

              const serviceAtNewPosition = servicesInqueue[event.newIndex];

              // if it's not in the queue, check the prequeue
              if (!service) {
                service = this.preQueueServices.find((x) => x.ServiceId == serviceId);
              }

              if (service) {
                if (
                  isInQueue === service.IsInPipelineQueue &&
                  newPipelineId === service.ServiceStationPipelineId &&
                  (!isInQueue || newSortIndex === service.SortIndex)
                ) {
                  // if we clicked and started dragging/sorting, but ended up in the same position,
                  // just return without running any updates - open the service instead
                  this.showServiceDetails(service.ServiceId, service.NetId);
                  return;
                } else if (!service.ServiceStationPipelineId && newPipelineId === '') {
                  // sorting within the prequeue is not supported, so cancel the event..
                  event.cancel();
                  return;
                }

                service.IsInPipelineQueue = isInQueue;
                service.ServiceStationPipelineId = newPipelineId;
                service.SortIndex = serviceAtNewPosition?.SortIndex || newSortIndex || 1;

                this.serviceService
                  .updateQueue(service.ServiceId, service)
                  .then(() => {
                    this.toastService.showSuccess('service.queueupdated');

                    this.refreshPreQueue();
                    this.refreshQueue();
                  })
                  .catch((err) => this.errorService.handleError(err));
              }
            });
          }, 100);
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private currentPageChanged(newValue, oldValue) {
    if (parseInt(newValue, 10) === parseInt(oldValue, 10)) {
      return;
    }

    this.refreshPreQueue();
  }

  setPipelineSearchFocus() {
    this.pipelineSearchInputHasFocus = true;
  }

  filterPipelineServices(newvalue: string, oldvalue: string) {
    this.queuedServices.forEach((x) => {
      x._isFilteredOut = false;
    });

    const filterWithOutSpace = this.replaceAll(newvalue, ' ', '').toLowerCase();
    const filterLower = newvalue.toLowerCase();
    const services = this.queuedServices.filter(
      (x) =>
        x.CustomerName.toLowerCase().indexOf(filterLower) == -1 &&
        this.replaceAll(x.NetIdentifier, ' ', '').toLowerCase().indexOf(filterWithOutSpace) == -1
    );

    services.forEach((x) => {
      x._isFilteredOut = true;
    });
  }

  private replaceAll(str, find, replace): string {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  refreshPreQueue() {
    const includeTested = this.filters.statuses.Tested ? true : this.filters.statuses.NotTested ? false : null;
    const includeWashed = this.filters.statuses.Washed ? true : this.filters.statuses.NotWashed ? false : null;
    const includeRepaired = this.filters.statuses.Repaired ? true : this.filters.statuses.NotRepaired ? false : null;
    const includeAntifouled = this.filters.statuses.Antifouled
      ? true
      : this.filters.statuses.NotAntifouled
        ? false
        : null;

    this.serviceService
      .getPreQueuedServices(
        this.serviceStationId,
        this.filters.searchText,
        this.filters.customerId,
        this.filters.plannedDeliveryDateFrom,
        this.filters.plannedDeliveryDateTo,
        includeTested,
        includeWashed,
        includeRepaired,
        includeAntifouled,
        this.filters.statuses.PutOnStorage,
        this.filters.statuses.PackedAndReady,
        this.filters.hasPlannedDeliveryDate,
        this.pageSize,
        (this.currentPage - 1) * this.pageSize
      )
      .then((response) => {
        this.preQueueTotalItems = response.headers.get('x-total-count');
        response.text().then((responseText) => {
          if (responseText) {
            this.preQueueServices = JSON.parse(responseText);
          }
        });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  refreshQueue() {
    this.serviceService
      .getQueuedServices(this.serviceStationId)
      .then((services) => {
        // loop through the servicestation's available pipelines
        this.pipelineData.forEach((pipelineGroup) => {
          pipelineGroup.Pipelines.forEach((pipeline) => {
            pipeline.Services = services.filter(
              (x) => x.ServiceStationPipelineId === pipeline.Id && !x.IsInPipelineQueue
            );
            pipeline.ServicesQueued = services.filter(
              (x) => x.ServiceStationPipelineId === pipeline.Id && x.IsInPipelineQueue
            );
          });
        });

        this.queuedServices = services;

        this.filterPipelineServices(this.pipelineFilters.searchText, null);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  updateStatusFilter(event, field) {
    this.currentPage = 1;
    this.refreshPreQueue();
  }

  setFilterCustomerId(event) {
    if (!event) {
      this.filters.customerId = null;
    } else {
      this.filters.customerId = this.filters.customerId = event.detail.value;
    }

    this.refreshPreQueue();
  }

  closeFilter() {
    this.closeDropdown(null);
  }

  private setSearchFocus(event) {
    if (event.detail) {
      this.searchInputHasFocus = event.detail.value;
    }
    if (!this.searchInputHasFocus) {
      this.filters.searchText = null;
      this.refreshPreQueue();
    }
  }

  private closeDropdown(event) {
    this.eventAggregator.publish('dropdownClose', 1);
  }

  changeServiceStation(serviceStation) {
    this.serviceStationId = serviceStation.Id;
    this.serviceStation = serviceStation;

    this.currentPage = 1;

    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = null;
    }

    this.getServiceStationData();
    this.refreshPreQueue();

    this.closeDropdown(null);
  }

  private showServiceDetails(id: number, netId: number) {
    if (this.openServiceAsDialog) {
      void this.dialogService.open({
        viewModel: ServiceDialog,
        model: { Id: id, NetId: netId },
        lock: false,
        position: () => {},
      });
    } else {
      this.router.navigateToRoute('service-detail', { Id: id, NetId: netId });
    }
  }

  private setFilterDate(dateToSet: string, event) {
    if (!event.firedBy) {
      this.filters[dateToSet] = null;
      this.refreshPreQueue();
      return;
    }

    const date = event.firedBy._d;

    if (
      date &&
      this.filters[dateToSet] &&
      moment.default(date).format('YYYY-MM-DD') == moment.default(this.filters[dateToSet]).format('YYYY-MM-DD')
    ) {
      return;
    }

    // hack because datepicker is slow
    setTimeout(() => {
      this.refreshPreQueue();
    }, 500);
  }

  private clearFilterDatepickers(param) {
    const paramFrom = param + 'From';
    const paramTo = param + 'To';
    this.filters[paramFrom] = null;
    this.filters[paramTo] = null;
    this.refreshPreQueue();
  }

  private setFilterHasPlannedDeliveryDate(value) {
    this.filters.hasPlannedDeliveryDate = value;
    this.refreshPreQueue();
    return true;
  }
}
